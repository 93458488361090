@import './variables.scss';

/* Reset 
--------------------------------------------------------------------------------------------------------*/
/* 
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* General 
--------------------------------------------------------------------------------------------------------*/
*,
*:after,
*:before {
    outline: none !important;
    box-sizing: border-box !important;
}

html,
body {
    min-width: 320px;
    min-height: 100vh;
}

body {
    font-size: 16px;
    line-height: 22px;
    -ms-overflow-style: scrollbar;
    -webkit-font-smoothing: subpixel-antialiased;

    @include theme using ($var) {
        background: map-get($var, bodyBackground);
        color: map-get($var, systemTextPrimary);
        font-family: map-get($var, fontFamily);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
}

input[type=text],
input[type=email],
input[type=password],
textarea {
    -webkit-appearance: none;
    appearance: none;
}

button {
    font-family: inherit;
    color: inherit;
}

::-webkit-input-placeholder {
    color: #000;
}

::-moz-placeholder {
    color: #000;
}

:-ms-input-placeholder {
    color: #000;
}

:-moz-placeholder {
    color: #000;
}

::-moz-focus-inner {
    border: 0;
}

select:-moz-focusring {
    color: transparent;
    border: 1px solid #000;
    text-shadow: 0 0 0 #000;
}

option:not(:checked) {
    color: black;
}

a {
    text-decoration: none;
    color: inherit;
}

.shadow-1 {
    @include theme using ($var) {
        box-shadow: map-get($var, shadow1) !important;
    }
}

.shadow-2 {
    @include theme using ($var) {
        box-shadow: map-get($var, shadow2) !important;
    }
}

.shadow-3 {
    @include theme using ($var) {
        box-shadow: map-get($var, shadow3) !important;
    }
}

.shadow-4 {
    @include theme using ($var) {
        box-shadow: map-get($var, shadow4) !important;
    }
}

.shadow-5 {
    @include theme using ($var) {
        box-shadow: map-get($var, shadow5) !important;
    }
}

/* Misc.
--------------------------------------------------------------------------------------------------------*/
.secondary-link {
    text-decoration: none;
    display: inline-block;
    vertical-align: top;

    @include theme using ($var) {
        color: map-get($var, systemTextSecondary);
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;

        @include theme using ($var) {
            color: map-get($var, systemTextPrimary);
        }
    }
}

.primary-link {
    text-decoration: none;
    display: inline-flex;
    gap: 6px;
    align-items: center;
    vertical-align: top;

    @include theme using ($var) {
        color: map-get($var, primary600);
    }

    &.dark {
        @include theme using ($var) {
            color: map-get($var, primary700);
        }
    }

    &.underlined {
        text-decoration: underline;
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;

        @include theme using ($var) {
            color: map-get($var, primary800);
        }

        &.underlined {
            text-decoration: none;
        }
    }
}

.text-brand {
    @include theme using ($var) {
        color: map-get($var, textBrandColor);
    }
}

.text-danger {
    @include theme using ($var) {
        color: map-get($var, textDangerColor);
    }
}

.text-success {
    @include theme using ($var) {
        color: map-get($var, textSuccessColor);
    }
}

.d-block {
    display: block !important;
}

.user-avatar-sm {
    display: block;
    height: 36px;
    width: 36px;
    border-radius: 50px;
}

.show-popover-backdrop {
    .MuiBackdrop-root {
        background: rgba(0, 0, 0, 0.24);
    }
}

.text-primary {
    @include theme using ($var) {
        color: map-get($var, systemTextPrimary) !important;
    }
}

.text-secondary {
    @include theme using ($var) {
        color: map-get($var, systemTextSecondary) !important;
    }
}

.font-normal {
    font-weight: 400;
}

.primary-container {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 1188px;
    margin: 0 auto;

    &.size-lg {
        max-width: 1288px;
    }

    &.checkout-layout {
        padding-top: 104px;
        padding-bottom: 64px;
    }
}

.text-truncate {
    @include text-truncate();
}

.m-0 {
    margin: 0 !important;
}

.ml-auto {
    margin-left: auto !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-4 {
    margin-left: 4px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.ml-12 {
    margin-left: 12px !important;
}

.ml-16 {
    margin-left: 16px !important;
}

.ml-n8 {
    margin-left: -8px !important;
}

.ml-n16 {
    margin-left: -16px !important;
}

.my-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}

.my-16 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}

.mx-n8 {
    margin-left: -8px !important;
    margin-right: -8px !important;
}

.mr-16 {
    margin-right: 16px !important;
}

.mr-n8 {
    margin-right: -8px !important;
}

.px-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.py-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.p-8 {
    padding: 8px !important;
}

.p-12 {
    padding: 12px !important;
}

.p-16 {
    padding: 16px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-24 {
    padding: 24px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-8 {
    padding-right: 8px !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-4 {
    padding-left: 4px !important;
}

.pl-8 {
    padding-left: 8px !important;
}

.pt-2 {
    padding-top: 2px !important;
}

.pt-4 {
    padding-top: 4px !important;
}

.pt-16 {
    padding-top: 16px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 1px !important;
}

.pb-2 {
    padding-bottom: 2px !important;
}

.pb-4 {
    padding-bottom: 4px !important;
}

.pb-8 {
    padding-bottom: 8px !important;
}

.pb-16 {
    padding-bottom: 16px !important;
}

.pb-24 {
    padding-bottom: 24px !important;
}

.pb-56 {
    padding-bottom: 56px !important;
}

.width-160 {
    width: 160px !important;
}

.minw0 {
    min-width: 0;
}

.minw720 {
    min-width: 720px;
}

.height-100p {
    height: 100% !important;
}

.height-100p.mb-16 {
    height: calc(100% - 16px) !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-24 {
    margin-bottom: 24px !important;
}

.mb-32 {
    margin-bottom: 32px !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-12 {
    margin-top: 12px !important;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-20 {
    margin-top: 21px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mt-n8 {
    margin-top: -8px !important;
}

.maxh-590 {
    max-height: 590px;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.row-click {
    cursor: pointer;
}

.bordered {
    @include theme using ($var) {
        border: 1px solid map-get($var, systemBorderLight) !important;
    }
}

.bg-weak {
    @include theme using ($var) {
        background: map-get($var, secondaryBackground) !important;
    }
}

.border-bottom {
    @include theme using ($var) {
        border-bottom: 1px solid map-get($var, systemBorderLight) !important;
    }
}

.border-top {
    @include theme using ($var) {
        border-top: 1px solid map-get($var, systemBorderLight) !important;
    }
}

hr {
    background: none;
    height: 1px;
    border: 0;
    margin: 0;

    @include theme using ($var) {
        border-bottom: 1px solid map-get($var, systemBorderLight);
    }

    &.strong {
        @include theme using ($var) {
            border-bottom: 1px solid map-get($var, systemBorder);
        }
    }
}

.vr {
    width: 1px;
    display: block;
    flex-shrink: 0;
    align-self: stretch;

    @include theme using ($var) {
        background-color: map-get($var, systemBorderLight);
    }

    &.strong {
        @include theme using ($var) {
            background-color: map-get($var, systemBorder);
        }
    }
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.avatar-initial {
    border-radius: 80px;
    display: block;
    @include title-medium-bold();
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
}

.page-search {
    width: 240px;
}

.page-action-bar {
    padding-bottom: 16px;

    .MuiCheckbox-root {
        margin: -3px;
    }
}

.text-underline {
    text-decoration: underline;
}

.form-group {
    margin-bottom: 24px;
}

.inline-details {
    display: flex;
    flex-wrap: wrap;
    @include body-medium();
    align-items: center;

    @include theme using ($var) {
        color: map-get($var, systemTextSecondary);
    }

    >li,
    >li>span {
        display: flex;
        align-items: center;

        &:not(:last-child, .hide-separator):after {
            content: '';
            margin: 0 8px;
            height: 4px;
            width: 4px;
            display: block;
            border-radius: 20px;

            @include theme using ($var) {
                background: map-get($var, systemTextTertiary);
            }
        }

        &.before-checkbox:after {
            display: none;
        }

        &.checkbox-wrapper {
            margin-left: auto;

            .MuiFormControlLabel-root {
                margin: -8px 0 -8px -12px;
            }
        }

        .title {
            padding-right: 4px;

            @include theme using ($var) {
                color: map-get($var, systemTextPrimary);
            }
        }

        >svg {
            display: block;
            height: 20px !important;
            width: 20px !important;
            margin-right: 6px;
        }

        .bold {
            @include body-medium-semibold();

            @include theme using ($var) {
                color: map-get($var, systemTextPrimary);
            }
        }
    }
}

.small-title {
    padding-bottom: 6px;
    display: block;

    @include theme using ($var) {
        color: map-get($var, systemTextTertiary);
    }
}

.form-group-static {
    position: relative;

    .control-label-static {
        display: block;
        @include body-small();

        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }
    }

    .form-control-static {
        @include body-medium-medium();
    }

    &.easy-edit {
        &:before {
            content: '';
            position: absolute;
            display: block;
            border-radius: 8px;
            left: -12px;
            top: -3px;
            bottom: -3px;
            right: 0;
            transition: all 0.3s ease;
            opacity: 0;
            visibility: hidden;

            @include theme using ($var) {
                border: 1px solid map-get($var, systemBorder);
            }
        }

        .easy-edit-wrapper:not(.easy-edit-not-allowed) &:hover:before {
            opacity: 1;
            visibility: visible;
        }

        a {
            position: relative;
            z-index: 1;
        }
    }
}

.attachment-btn {
    display: flex;
    align-items: center;
    border: 0;
    background: none;
    transition: all 0.3s ease;
    border-radius: 50px;
    @include label-large-bold();
    padding: 10px 8px;
    color: inherit;

    &:hover,
    &:focus,
    &:active {
        background: rgba(0, 0, 0, 0.16);
    }

    svg {
        display: block;
        height: 20px !important;
        width: 20px !important;
        margin-left: 4px;
    }

    .MuiTable-root & {
        margin: -10px -8px;
    }
}

.edit-lead-deal-status {
    display: flex;
    align-items: center;

    >button {
        background: none;
        border: 0;
        padding: 0;
        opacity: 0.64;
        border-radius: 50px;
        margin-left: 4px;
        transition: all 0.3s ease;

        @include theme using ($var) {
            color: map-get($var, systemTextPrimary);
        }

        &:hover,
        &:focus,
        &:active {
            opacity: 1;
        }

        >svg {
            display: block;
            height: 20px !important;
            width: 20px !important;
        }
    }
}

.number-stepper {
    width: 104px;

    .MuiFilledInput-input.MuiInputBase-inputAdornedEnd {
        padding-left: 0;
    }

    .MuiInputBase-root.MuiFilledInput-root {
        padding-left: 0;
        padding-right: 0;
    }
}

.control-hint {
    position: relative;

    .hint {
        position: absolute;
        top: calc(100% + 2px);
        right: 0;
        text-align: right;
        @include label-medium-bold();

        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }

        &.bottom {
            bottom: 6px;
            top: auto;
        }
    }
}

.view-location-map {
    height: 480px;
    width: 100%;
    display: block;
    border-radius: 8px;

    @include theme using ($var) {
        border: 1px solid map-get($var, systemBorderLight);
    }
}

.track-user-map {
    display: block;
    border-radius: 12px;
    width: 100%;
    height: calc(100vh - 244px);
    min-height: 480px;

    @include theme using ($var) {
        box-shadow: map-get($var, shadow1);
    }
}

.user-avatar {
    display: block;
    height: 48px;
    width: 48px;
    border-radius: 50px;
    object-fit: cover;

    @include theme using ($var) {
        box-shadow: map-get($var, shadow1);
        background: map-get($var, neutral25);
    }
}

.product-thumbnail {
    display: block;
    border-radius: 8px;
    object-fit: cover;
    height: 44px;
    width: 44px;

    @include theme using ($var) {
        background: map-get($var, secondaryBackground);
        border: 1px solid map-get($var, systemBorderLight);
    }
}

.thumbnail-wrapper {
    position: relative;

    .thumbnail-edit-btn {
        position: absolute;
        right: -8px;
        bottom: -8px;

        .action-btn {
            padding: 4px;
            border-radius: 50px;
            border: 0;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;

            @include theme using ($var) {
                background: map-get($var, btnPrimaryBackground);
                color: map-get($var, btnPrimaryTextColor);
            }

            svg {
                display: block;
            }
        }
    }
}

.icon-badge {
    height: 52px;
    width: 52px;
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    @include theme using ($var) {
        background: map-get($var, textBrandColor);
        color: map-get($var, btnTextColor);
    }
}

.unsaved-contacts {
    text-align: center;
    margin-bottom: 24px;

    .MuiGrid-item:not(:last-child) {
        @include theme using ($var) {
            border-right: 1px solid map-get($var, systemBorderLight);
        }
    }
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1;
}

.campaign-stat {
    width: 100%;
    display: block;
    background: none;
    border: 0;
    position: relative;
    padding: 12px 16px;
    cursor: pointer;
    transition: all 0.3s ease;

    &.active:before {
        position: absolute;
        content: '';
        display: block;
        height: 3px;
        left: 0;
        right: 0;
        bottom: 0;

        @include theme using ($var) {
            background: map-get($var, textBrandColor);
        }
    }

    &:hover,
    &:focus,
    &:active {
        @include theme using ($var) {
            background: transparentize($color: map-get($var, btnHoverSecondaryBackgroundColor), $amount: 0.8);
        }
    }

    .MuiGrid-item & {
        @include theme using ($var) {
            border-right: 1px solid map-get($var, systemBorderLight);
        }
    }

    .MuiGrid-item:last-child & {
        border-right: none;
    }
}

.text-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.social-btns {
    display: flex;
    align-items: center;

    @include theme using ($var) {
        color: map-get($var, systemTextSecondary);
    }

    .icon-button {
        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }

        svg {
            height: 22px !important;
            width: 22px !important;
        }
    }
}

.vertical-action-btns {
    display: flex;
    gap: 16px;

    li {
        flex-grow: 1;
        flex-basis: 0;
        position: relative;

        .vertical-action-btn {
            width: 100%;
        }

        &:not(:last-child):after {
            content: '';
            position: absolute;
            top: 8px;
            bottom: 8px;
            right: -8px;
            width: 1px;

            @include theme using ($var) {
                background: map-get($var, systemBorderLight);
            }
        }
    }
}

.vertical-action-btn {
    padding: 8px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    background: none;
    border: 0;
    transition: all 0.3s ease;
    border: 1px solid transparent;
    cursor: pointer;
    @include label-large();

    svg {
        @include theme using ($var) {
            color: map-get($var, checkboxRadioCheckedColor);
        }
    }

    &:hover,
    &:focus,
    &:active {
        @include theme using ($var) {
            background: map-get($var, wrapperBackground);
            border-color: map-get($var, btnSecondaryBackgroundColor);
        }
    }
}

/* Empty State
--------------------------------------------------------------------------------------------------------*/
.empty-state {
    text-align: center;
    padding: 96px 24px;

    >img {
        display: block;
        height: 160px;
        width: 160px;
        margin: 0 auto 32px;
    }

    >h4 {
        @include headline-medium-bold();
    }

    >p {
        padding-top: 8px;
        max-width: 320px;
        margin: 0 auto;
        @include body-large-medium();

        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }
    }

    .action {
        margin-top: 24px;
    }

    &.size-small {
        padding: 48px 16px;

        >img {
            height: 96px;
            width: 96px;
            margin-bottom: 20px;
        }

        >h4 {
            @include title-large-semibold();
        }

        >p {
            @include body-medium();
        }

        .action {
            margin-top: 16px;
        }
    }
}

.theme-xl-financial .dark-gradient-text {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.91) 50%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Login Pages
--------------------------------------------------------------------------------------------------------*/
.login-banner {
    background: url('../images/login-banner.jpg') center no-repeat;
    background-size: cover;
    height: 100%;
    width: calc(100% + 40px);
    position: relative;

    &:after {
        content: '';
        height: 100%;
        width: 80px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        @include theme using ($var) {
            background-image: url(map-get($var, loginCutImage));
        }

    }
}

.login-form-wrapper {
    width: 326px;
    max-width: 100%;
    margin: 0 auto;

    .back-btn {
        margin-bottom: 12px;
    }
}

.login-form-outer {
    display: flex;
    align-items: center;
    min-height: 100vh;
    padding: 40px;
}

.login-logo {
    max-height: 80px;
    display: block;
    margin-bottom: 24px;
}

.login-title {
    padding-bottom: 8px;

    @include theme using ($var) {
        color: map-get($var, systemTextPrimary);
    }
}

.login-subtitle {
    padding-bottom: 32px;

    strong {
        @include body-large-bold();

        @include theme using ($var) {
            color: map-get($var, systemTextPrimary);
        }
    }

    @include theme using ($var) {
        color: map-get($var, systemTextSecondary);
    }
}

.forgot-password-link {
    text-align: right;
    margin-top: -16px;
    margin-bottom: 24px;
}

.login-signup {
    text-align: center;
    padding-top: 32px;

    @include theme using ($var) {
        color: map-get($var, systemTextTertiary);
    }

    .primary-link {
        margin-left: 4px;
    }
}

.reset-password-info {
    text-align: center;

    img {
        display: block;
        max-height: 160px;
        margin: 0 auto 16px;
    }

    .login-subtitle {
        padding-bottom: 24px;
    }

    button,
    a {
        width: 100%;
        max-width: 200px;
    }
}

/* icon button
--------------------------------------------------------------------------------------------------------*/
.icon-button {
    border-radius: 50px;
    transition: all 0.3s ease;
    padding: 8px;
    border: 0;
    background: none;
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;

    @include theme using ($var) {
        color: map-get($var, systemTextPrimary);
    }

    &:hover,
    &:focus,
    &:active {
        background: rgba(0, 0, 0, 0.16);
    }

    &.light {
        @include theme using ($var) {
            color: map-get($var, systemWhite);
        }

        &:hover,
        &:focus,
        &:active {
            background: rgba(255, 255, 255, 0.1);
        }
    }

    &.size-large {
        padding: 12px;
    }

    &.size-medium {
        padding: 10px;
    }

    &.size-small {
        padding: 8px;

        svg {
            height: 20px !important;
            width: 20px !important;
        }
    }

    svg {
        display: block;
    }

    &.has-notification:after {
        content: '';
        position: absolute;
        height: 8px;
        width: 8px;
        top: 18px;
        right: 14px;
        border-radius: 10px;

        @include theme using ($var) {
            border: 1px solid map-get($var, systemWhite);
            background-color: map-get($var, dangerError500);
        }
    }

    &.floating-action {
        position: fixed;
        right: 16px;
        bottom: 16px;
        z-index: 9;

        @include theme using ($var) {
            box-shadow: map-get($var, shadow4);
            background: map-get($var, neutral800);
            color: map-get($var, systemWhite);
        }
    }

    &.text-secondary {
        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }
    }
}

/* mobile not supported
--------------------------------------------------------------------------------------------------------*/
.mobile-not-supported {
    align-items: center;
    padding: 32px;
    text-align: center;
    min-height: 100vh;
    display: none;

    .content {
        margin: auto;
        padding: 48px 0;
        max-width: 400px;
    }

    .logo {
        display: block;
        max-height: 56px;
        margin: 0 auto;
    }

    .illustration {
        max-width: 280px;
        display: block;
        margin: 0 auto 32px;
    }

    h1 {
        padding-bottom: 8px;

        @include theme using ($var) {
            color: map-get($var, systemTextPrimary);
        }
    }

    p {
        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }
    }

    .badge {
        margin-top: 32px;
        display: inline-block;

        img {
            display: block;
            max-height: 48px;
        }
    }
}

/* main layout
--------------------------------------------------------------------------------------------------------*/
.main-layout {
    display: flex;
    min-height: 100vh;

    @include theme using ($var) {
        background: map-get($var, wrapperBackground);
    }

    .sidebar {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 280px;
        max-width: 280px;
    }

    .main-content {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 280px);
        max-width: calc(100% - 280px);
        padding: 24px 32px 76px 32px;
    }
}

/* menu
--------------------------------------------------------------------------------------------------------*/
.menu-title {
    padding-bottom: 16px;
    display: flex;
    align-items: center;

    .count {
        @include label-large-bold();
        padding: 6px 12px;
        border-radius: 8px;
        margin-left: auto;
        display: block;

        @include theme using ($var) {
            color: map-get($var, primary800);
            background: map-get($var, primary50);
        }
    }

    .icon-button {
        margin: -4px -8px -4px auto;
    }
}

/* help menu
--------------------------------------------------------------------------------------------------------*/
.help-menu.MuiPopover-paper {
    width: 100%;
    max-width: 380px;
}

/* attachment center
--------------------------------------------------------------------------------------------------------*/
.attachment-center {
    .title {
        padding-bottom: 8px;

        .file-size {
            @include theme using ($var) {
                color: map-get($var, systemTextSecondary);
            }
        }
    }

    .psuedo-upload-block {
        margin-bottom: 12px;
    }

    .attachments {
        padding-bottom: 24px;
    }
}

.attachments {
    &:not(:last-child) {
        margin-bottom: 24px;
    }

    >li {
        position: relative;

        >a {
            padding: 8px 52px 8px 12px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            transition: all 0.3s ease;

            @include theme using ($var) {
                border: 1px solid map-get($var, systemBorder);
            }

            &:last-child {
                padding-right: 12px;
            }

            &:hover,
            &:focus,
            &:active {
                @include theme using ($var) {
                    background: map-get($var, secondaryBackground);
                    border-color: map-get($var, neutral500);
                }
            }

            .icon-wrapper {
                padding: 8px;
                border-radius: 8px;
                flex-shrink: 0;
                margin-right: 8px;

                @include theme using ($var) {
                    color: map-get($var, systemTextSecondary);
                    background: map-get($var, secondaryBackground);
                }

                svg {
                    display: block;
                }
            }

            &.has-error {
                @include theme using ($var) {
                    border-color: map-get($var, dangerError);
                    background-color: map-get($var, dangerError25);
                }

                .icon-wrapper {
                    @include theme using ($var) {
                        background-color: map-get($var, dangerError50);
                    }
                }
            }

            .info {
                min-width: 0;

                p {
                    @include text-truncate();
                }

                span {
                    display: block;
                    @include text-truncate();

                    @include theme using ($var) {
                        color: map-get($var, systemTextTertiary);
                    }
                }
            }
        }

        .icon-button {
            position: absolute;
            top: 25px;
            right: 8px;
            opacity: 0.48;

            &:hover,
            &:focus,
            &:active {
                opacity: 1;
            }
        }

        .error-text {
            @include label-small-semibold();
            display: block;
            padding-top: 2px;

            @include theme using ($var) {
                color: map-get($var, dangerError);
            }
        }
    }

    &.single {
        >li {
            .icon-button {
                top: 13px;
            }
        }
    }

    &.inline {
        display: inline-block;
        vertical-align: top;
    }
}

/* psuedo upload block
--------------------------------------------------------------------------------------------------------*/
.psuedo-upload-block {
    position: relative;
    cursor: pointer;

    &.inline {
        display: inline-block;
        vertical-align: top;
    }

    .psuedo-upload-element {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
    }

    .content {
        z-index: 0;
        cursor: pointer;
    }
}

/* notification menu
--------------------------------------------------------------------------------------------------------*/
.notification-menu.MuiPopover-paper {
    width: 100%;
    max-width: 380px;
    max-height: 520px;
}

.notifications {
    margin-bottom: 12px;

    >li {
        >a {
            padding: 12px 16px;
            border-radius: 12px;
            color: inherit;
            text-decoration: none;
            display: block;
            position: relative;
            transition: all 0.3s ease;

            @include theme using ($var) {
                border: 1px solid map-get($var, systemBorderLight);
            }

            &:hover,
            &:focus,
            &:active {
                @include theme using ($var) {
                    border-color: map-get($var, systemBorder);
                    background: map-get($var, secondaryBackground);
                }
            }

            &.has-notification:after {
                content: '';
                position: absolute;
                display: block;
                height: 8px;
                width: 8px;
                border-radius: 10px;
                top: 12px;
                right: 12px;

                @include theme using ($var) {
                    background: map-get($var, primary);
                    box-shadow: 0 0 6px map-get($var, primary);
                }
            }

            span {
                display: block;
                padding-top: 6px;

                @include theme using ($var) {
                    color: map-get($var, systemTextTertiary);
                }
            }
        }
    }

    >li+li {
        padding-top: 8px;
    }
}

/* utility menu
--------------------------------------------------------------------------------------------------------*/
.utility-menu.MuiPopover-paper {
    width: 100%;
    max-width: 240px;
    padding: 16px;

    & .MuiRadioGroup-root {
        margin-bottom: 12px;
    }

    &.size-lg {
        max-width: 360px;
    }
}

/* notification list
--------------------------------------------------------------------------------------------------------*/
.notification-list {
    padding-bottom: 16px;

    >li {
        position: relative;

        .card {
            padding: 12px;

            .date {
                padding-top: 6px;
                display: block;

                @include theme using ($var) {
                    color: map-get($var, systemTextTertiary);
                }
            }
        }

        &.unread:after {
            content: '';
            position: absolute;
            display: block;
            height: 8px;
            width: 8px;
            border-radius: 10px;
            top: 12px;
            right: 12px;

            @include theme using ($var) {
                background: map-get($var, primary);
                box-shadow: 0 0 6px map-get($var, primary);
            }
        }

        .grid-checkbox {
            margin: -9px -1px 0 -9px;
        }
    }

    >li+li {
        margin-top: 8px;
    }
}

/* lead list
--------------------------------------------------------------------------------------------------------*/
.primary-list {
    padding-bottom: 16px;

    >li {
        position: relative;

        .card {
            padding: 12px;
            display: block;
        }

        .actions {
            position: absolute;
            right: 8px;
            top: 8px;
        }

        .other-info {
            margin-left: 88px;
            margin-top: 6px;
            padding-top: 6px;

            @include theme using ($var) {
                border-top: 1px solid map-get($var, systemBorderLight);
            }

            &.no-avatar {
                margin-left: 36px;
            }
        }

        .grid-checkbox {
            margin-left: -9px;
            margin-right: 3px;
        }
    }

    >li+li:not(.MuiGrid-item) {
        margin-top: 8px;
    }
}

.track-users-wrapper {
    margin-right: -12px;
}

.track-users {
    padding-right: 12px;
    padding-bottom: 12px;
    min-height: 480px;
    height: calc(100vh - 232px);
    align-content: flex-start;
    overflow: auto;
}

/* delete dialog
--------------------------------------------------------------------------------------------------------*/
.delete-dialog {
    text-align: center;

    .icon-wrapper {
        padding: 16px;
        margin: 8px auto 24px;
        border-radius: 50px;

        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
            background: map-get($var, secondaryBackground);
        }

        svg {
            display: block;
        }
    }

    .title {
        padding-bottom: 8px;
    }

    .message {
        padding-bottom: 32px;
        max-width: 310px;
        margin: 0 auto;

        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }
    }
}

/* status badge
--------------------------------------------------------------------------------------------------------*/
.status-badge {
    @include label-small-semibold();
    padding: 3px 6px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;

    @include theme using ($var) {
        color: map-get($var, statusBadgeDefaultTextColor);
        background: map-get($var, statusBadgeDefaultBackgroundColor);
    }

    &.success {
        @include theme using ($var) {
            color: map-get($var, statusBadgeSuccessTextColor);
            background: map-get($var, statusBadgeSuccessBackgroundColor);
        }
    }

    &.warning {
        @include theme using ($var) {
            color: map-get($var, statusBadgeWarningTextColor);
            background: map-get($var, statusBadgeWarningBackgroundColor);
        }
    }

    &.danger {
        @include theme using ($var) {
            color: map-get($var, statusBadgeErrorTextColor);
            background: map-get($var, statusBadgeErrorBackgroundColor);
        }
    }

    &.dark {
        @include theme using ($var) {
            color: map-get($var, statusBadgeDarkTextColor);
            background: map-get($var, statusBadgeDarkBackgroundColor);
        }
    }

    &.primary-filled {
        @include theme using ($var) {
            background: map-get($var, statusBadgePrimaryBackgroundColor);
            color: map-get($var, statusBadgePrimaryTextColor);
        }
    }

    &.size-lg {
        @include label-large-bold();
        padding: 6px 8px;
    }
}

/* card
--------------------------------------------------------------------------------------------------------*/
.card {
    position: relative;
    border-radius: 12px;
    transition: all 0.3s ease;

    &.sticky-bottom,
    &.sticky-top {
        position: sticky;
    }

    @include theme using ($var) {
        box-shadow: map-get($var, shadow1);
        background: map-get($var, cardBackground);
    }

    .card {
        box-shadow: none;
        background: none;

        @include theme using ($var) {
            border: 1px solid map-get($var, systemBorderLight);
        }
    }

    &.flex {
        display: flex;
        flex-direction: column;

        >div,
        >span,
        >p,
        >h1,
        >h2,
        >h3,
        >h4,
        >h5,
        >h6,
        >a,
        >button {
            flex-shrink: 0;
        }

        >.item-grow {
            flex-grow: 1;
        }
    }

    &.overflow-hidden {
        overflow: hidden;
    }

    a.card {

        &:hover,
        &:focus,
        &:active {
            box-shadow: none;

            @include theme using ($var) {
                border-color: map-get($var, systemBorder);
            }
        }
    }

    .card-header {
        display: flex;
        padding-bottom: 16px;

        .title {
            @include title-medium-large-semibold();
        }

        .action {
            margin: -6px -6px -2px auto;
        }
    }

    &.structured {
        .card-header {
            padding: 16px 20px 12px 20px;
            align-items: baseline;

            @include theme using ($var) {
                border-bottom: 1px solid map-get($var, systemBorderLight);
            }
        }

        .card-body {
            padding: 20px;
        }
    }

    &.grey {
        box-shadow: none;
        border: none;

        @include theme using ($var) {
            background: map-get($var, secondaryBackground);
        }
    }
}

a.card {

    &:hover,
    &:focus,
    &:active {
        @include theme using ($var) {
            box-shadow: map-get($var, shadow2);
        }
    }
}

/* Dashboard card
--------------------------------------------------------------------------------------------------------*/
.dashboard-card {
    padding: 20px 20px 16px 20px;

    span {
        display: block;
        padding-bottom: 4px;

        @include theme using ($var) {
            color: map-get($var, systemTextTertiary);
        }
    }

    svg {
        position: absolute;
        height: 32px !important;
        width: 32px !important;
        top: 20px;
        right: 20px;

        @include theme using ($var) {
            color: map-get($var, primary600);
        }
    }

    .dark-gradient-text {
        display: inline-block;
        vertical-align: top;
    }
}

/* dashboard list
--------------------------------------------------------------------------------------------------------*/
.dashboard-list {
    >li {
        margin-top: 10px;
        padding-bottom: 10px;
        position: relative;

        @include theme using ($var) {
            border-bottom: 1px solid map-get($var, systemBorderLight);
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }

        >span:not(:last-child) {
            display: block;
            padding-bottom: 2px;

            @include theme using ($var) {
                color: map-get($var, systemTextSecondary);
            }
        }

        .status-badge {
            position: absolute;
            right: 0;
            top: 0;
            display: block;
        }

        .info-block {
            display: flex;
            align-items: center;

            svg {
                height: 18px !important;
                width: 18px !important;
                margin-right: 6px;
                flex-shrink: 0;

                @include theme using ($var) {
                    color: map-get($var, systemTextSecondary);
                }
            }

            span {
                @include text-truncate();
                display: block;
                min-width: 0;
            }
        }

        &.lead {
            padding-left: 48px;

            .avatar-initial {
                position: absolute;
                top: 0;
                left: 0;
            }

            >span:not(:last-child) {
                padding-bottom: 4px;
            }
        }
    }
}

/* user menu
--------------------------------------------------------------------------------------------------------*/
.user-menu {
    &.MuiPopover-paper {
        width: 100%;
        max-width: 320px;
    }

    .head {
        padding: 28px 24px 20px;
        text-align: center;

        @include theme using ($var) {
            background: map-get($var, secondary900);
            color: map-get($var, systemWhite);
        }

        img {
            border-radius: 80px;
            height: 80px;
            width: 80px;
            object-fit: cover;
            display: block;
            margin: 0 auto 12px;

            @include theme using ($var) {
                box-shadow: map-get($var, shadow2);
            }
        }

        h6 {
            margin-bottom: -2px;
            @include text-truncate();
        }

        span {
            opacity: 0.64;
            @include text-truncate();
            display: block;
        }
    }

    .account-select {
        padding: 16px;

        @include theme using ($var) {
            border-bottom: 1px solid map-get($var, systemBorderLight);
        }

        .MuiFormControl-root {
            margin-bottom: 0;
        }
    }
}

/* header
--------------------------------------------------------------------------------------------------------*/
.header {
    padding-bottom: 24px;
    margin-right: -4px;
    display: flex;
    align-items: center;

    .right-nav {
        margin-left: auto;
        display: flex;
        align-items: center;

        >li+li {
            margin-left: 8px;
        }
    }
}

.user-menu-trigger {
    border: 0;
    padding: 4px;
    margin: 0;
    background: none;
    display: block;
    border-radius: 50px;
    transition: all 0.3s ease;

    &:hover,
    &:focus,
    &:active {
        background: rgba(0, 0, 0, 0.16);
    }

    img {
        display: block;
        border-radius: 50px;
        height: 40px;
        width: 40px;
        object-fit: cover;

        @include theme using ($var) {
            box-shadow: map-get($var, shadow2);
        }
    }
}

/* sidebar
--------------------------------------------------------------------------------------------------------*/
.sidebar {
    position: relative;
    z-index: 10;

    @include theme using ($var) {
        background: map-get($var, sidebarBackground);
        box-shadow: map-get($var, shadow3);
    }

    .logo-wrapper {
        padding: 24px 12px 40px 24px;
        display: flex;
        align-items: center;
        position: relative;

        .logo {
            display: block;
            max-width: 100%;
            max-height: 40px;
        }

        .logo-collapsed {
            max-height: 48px;
            display: none;
        }

        .icon-button {
            margin-left: auto;
            flex-shrink: 0;
        }
    }

    .main-nav {
        >li {
            >a {
                @include body-large-semibold();
                transition: all 0.3s ease;
                opacity: 0.72;
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 20px 16px 20px 24px;
                position: relative;

                @include theme using ($var) {
                    color: map-get($var, systemWhite);
                }

                &:hover,
                &:focus,
                &:active {
                    background: rgba(255, 255, 255, 0.1);
                }

                &.active {
                    background: rgba(0, 0, 0, 0.16);
                    opacity: 1;
                }

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 12px;
                    bottom: 12px;
                    left: 0;
                    width: 4px;
                    border-radius: 0 2px 2px 0;
                    transform: scaleY(0);
                    opacity: 0;
                    visibility: hidden;

                    @include theme using ($var) {
                        background: map-get($var, primary400);
                    }
                }

                &.active:after {
                    opacity: 1;
                    visibility: visible;
                    transform: scaleY(1);
                }

                svg {
                    margin-right: 16px;
                    flex-shrink: 0;
                }

                span {
                    min-width: 0;
                    @include text-truncate();
                }
            }
        }
    }
}

body.sidebar-collapsed {
    .sidebar {
        .logo-wrapper {
            padding: 12px 16px 48px 16px;
            flex-direction: column-reverse;

            .logo {
                display: none;
            }

            .logo-collapsed {
                display: block;
                margin-top: 4px;
            }
        }

        .main-nav {
            >li {
                >a {
                    padding: 20px 28px;

                    svg {
                        margin: 0;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }
    }

    .main-layout {
        .sidebar {
            flex-basis: 80px;
            max-width: 80px;
        }

        .main-content {
            flex-basis: calc(100% - 80px);
            max-width: calc(100% - 80px);
        }
    }
}

/* dialog
--------------------------------------------------------------------------------------------------------*/
.dialog-title {
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    h2 {
        @include headline-small-bold();
    }

    .dialog-close {
        margin: -4px -8px -4px auto;
    }
}

/* Date picker
--------------------------------------------------------------------------------------------------------*/
.MuiPaper-root.MuiPickersPopper-paper {
    color: inherit;

    @include theme using ($var) {
        background: map-get($var, cardBackground);
    }

    .MuiButtonBase-root.MuiPickersDay-root {
        color: inherit;
        font-size: 14px;
    }

    .MuiDayCalendar-weekDayLabel,
    .MuiPickersArrowSwitcher-button,
    .MuiPickersCalendarHeader-switchViewButton {
        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }
    }

    .MuiButtonBase-root.MuiPickersDay-root.Mui-selected,
    .MuiPickersYear-yearButton.Mui-selected,
    .MuiPickersMonth-monthButton.Mui-selected,
    .MuiMultiSectionDigitalClockSection-item.Mui-selected {
        @include theme using ($var) {
            color: map-get($var, btnTextColor);
        }
    }
}

.date-picker-popper {
    transform: translate(0, 0) !important;
    z-index: 1400;
    inset: 0 0 0 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed !important;

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        display: block;
        right: 0;
        bottom: 0;
        z-index: 0;
        transition: all 0.3s ease;

        @include theme using ($var) {
            background-color: map-get($var, dialogBackdropColor);
        }
    }

    &>.MuiPaper-root {
        position: relative;
        z-index: 1;
    }
}

/* drag and drop upload
--------------------------------------------------------------------------------------------------------*/
.dnd-block {
    text-align: center;
    padding: 24px 32px;
    border-radius: 8px;
    margin-bottom: 24px;

    @include theme using ($var) {
        border: 2px dashed map-get($var, systemBorder);
    }

    &.view {
        border-width: 1px;
        border-style: solid;
    }

    .icon {
        display: block;
        margin: 0 auto 16px;

        @include theme using ($var) {
            color: map-get($var, primary600);
        }
    }

    .details {
        display: flex;
        justify-content: center;
        @include body-medium();
        margin-bottom: 12px;
        align-items: center;

        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }

        li {
            display: flex;
            align-items: center;

            &:not(:last-child):after {
                content: '';
                display: block;
                height: 4px;
                width: 4px;
                margin: 0 6px;
                border-radius: 8px;

                @include theme using ($var) {
                    background: map-get($var, systemTextSecondary);
                }
            }
        }
    }
}

/* loader
--------------------------------------------------------------------------------------------------------*/
.loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    flex-direction: column;

    @include theme using ($var) {
        background: map-get($var, loaderBackground);
    }

    .loader-visible & {
        opacity: 1;
        visibility: visible;
    }

    .message {
        padding-top: 24px;
        text-align: center;
    }
}

/* activity list
--------------------------------------------------------------------------------------------------------*/
.activity-list {
    max-height: 660px;
    overflow: auto;

    &.height-auto {
        max-height: none;
        overflow: visible;
    }

    .activity {
        border-radius: 12px;
        padding: 12px;
        padding-right: 48px;
        position: relative;
        height: 100%;

        @include theme using ($var) {
            border: 1px solid map-get($var, systemBorderLight);
        }

        &.card {
            border: 0;
        }

        .action {
            position: absolute;
            top: 8px;
            right: 8px;
        }

        .icon {
            flex-shrink: 0;

            @include theme using ($var) {
                color: map-get($var, systemTextTertiary);
            }
        }

        .details {
            min-width: 0;

            p {
                @include text-truncate();
            }

            .inline-details:not(:last-child) {
                padding-bottom: 8px;
            }
        }

        &.undone {
            margin-left: 12px;
            padding-left: 20px;
        }

        &.expired {
            .icon {
                opacity: 0.48;
            }

            .details {
                p {
                    opacity: 0.48;
                }

                .inline-details {
                    li:not(.badge-wrapper) {
                        opacity: 0.48;
                    }
                }
            }
        }

        &.has-attachment {
            padding-right: 88px;
        }

        &.no-action {
            padding-right: 12px;

            &.has-attachment {
                padding-right: 48px;
            }

            .attachment-btn {
                right: 8px;
            }
        }

        .attachment-btn {
            position: absolute;
            top: 8px;
            right: 40px;
        }

        .check {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding: 0;
            border-radius: 50px;
            transition: all 0.3s ease;
            padding: 2px;
            left: -12px;
            cursor: pointer;

            @include theme using ($var) {
                color: map-get($var, checkBtnTextColor);
                border: 1px solid map-get($var, checkBtnBorderColor);
                background: map-get($var, checkBtnBackgroundColor);
            }

            &:hover,
            &:focus,
            &:active {
                @include theme using ($var) {
                    color: map-get($var, checkBtnHoverTextColor);
                    background: map-get($var, checkBtnHoverBackgroundColor);
                    border-color: map-get($var, checkBtnHoverBorderColor);
                }
            }

            svg {
                height: 18px !important;
                width: 18px !important;
                display: block;
            }
        }
    }
}

.activity-wrapper.MuiGrid-root {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* notes
--------------------------------------------------------------------------------------------------------*/
.note {
    border-radius: 12px;
    padding: 12px;
    position: relative;
    height: 100%;

    @include theme using ($var) {
        border: 1px solid map-get($var, systemBorderLight);
    }

    .action {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    >span {
        display: block;
        padding-bottom: 8px;

        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }
    }

    >h6 {
        @include text-truncate();
    }

    >span,
    >h6 {
        padding-right: 36px;
    }

    &.has-attachment {

        >span,
        >h6 {
            padding-right: 76px;
        }
    }

    .attachment-btn {
        position: absolute;
        top: 8px;
        right: 40px;
    }
}

/* Easy Edit
--------------------------------------------------------------------------------------------------------*/
.easy-edit-hover-on.easy-edit-wrapper {
    font-style: normal;
}

.easy-edit-inline-wrapper {
    position: relative;
    z-index: 1;

    .easy-edit-component-wrapper {
        display: block;

        .control {
            margin: -3px 0 -3px -12px;
        }
    }

    .easy-edit-button-wrapper {
        position: absolute;
        top: 32px;
        right: 8px;
        display: flex;
        gap: 4px;
        z-index: 1;

        .easy-edit-button {
            margin: 0;
            border-radius: 50px;
            transition: all 0.3s ease;
            cursor: pointer;
            padding: 3px;

            @include theme using ($var) {
                box-shadow: map-get($var, shadow1);
            }

            svg {
                display: block;
                height: 14px !important;
                width: 14px !important;
            }

            &[name="save"] {
                @include theme using ($var) {
                    background: map-get($var, easyEditSaveBtnBackgroundColor);
                    color: map-get($var, easyEditSaveBtnTextColor);
                }

                &:hover,
                &:focus,
                &:active {
                    @include theme using ($var) {
                        background: map-get($var, easyEditSaveBtnHoverBackgroundColor);
                    }
                }
            }

            &[name="cancel"] {
                padding: 2px;

                @include theme using ($var) {
                    background: map-get($var, easyEditCancelBtnBackgroundColor);
                    color: map-get($var, easyEditCancelBtnTextColor);
                    border: 1px solid map-get($var, systemBorder);
                }

                &:hover,
                &:focus,
                &:active {
                    @include theme using ($var) {
                        background: map-get($var, easyEditCancelBtnHoverBackgroundColor);
                    }
                }
            }
        }
    }
}

.easy-edit-validation-error {
    @include label-medium();
    margin-top: 2px;
    margin-bottom: -6px;
    display: block;

    @include theme using ($var) {
        color: map-get($var, dangerError);
    }
}

/* page tabs
--------------------------------------------------------------------------------------------------------*/
.page-tabs {
    padding: 12px 0;
    margin-bottom: 12px;
    margin-top: -2px;
    overflow: auto;
    white-space: nowrap;

    @include theme using ($var) {
        border-top: 1px solid map-get($var, systemBorderLight);
        border-bottom: 1px solid map-get($var, systemBorderLight);
    }

    .MuiButton-text {
        opacity: 0.56;

        &:hover,
        &:focus,
        &:active {
            opacity: 1;
        }
    }
}

/* kanban wrapper
--------------------------------------------------------------------------------------------------------*/
.kanban-wrapper {
    overflow: auto;
    margin: 0 -32px -76px;
    padding: 0 32px;
    height: calc(100vh - 148px);

    .title-wrapper {
        display: flex;
        position: sticky;
        top: 0;
        z-index: 1;

        >li {
            flex-basis: 320px;
            flex-grow: 0;
            flex-shrink: 0;
            max-width: 320px;
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 0;
            margin-bottom: 8px;

            @include theme using ($var) {
                background: map-get($var, wrapperBackground);
            }

            &:not(:last-child) {
                margin-right: 16px;
            }

            .status-badge {
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }
    }

    .board-wrapper {
        display: flex;

        .stage {
            flex-basis: 320px;
            flex-grow: 0;
            flex-shrink: 0;
            max-width: 320px;
            margin-right: 16px;
        }

        &:after {
            flex-basis: 16px;
            flex-grow: 0;
            flex-shrink: 0;
            max-width: 16px;
            content: '';
            display: block;
        }
    }
}

.deal-lead-card {
    padding: 12px 16px;
    display: block;

    p {
        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }
    }

    .title {
        margin-top: -2px;
    }

    .bottom-block {
        margin-top: 10px;
        padding-top: 10px;
        @include body-medium();

        @include theme using ($var) {
            border-top: 1px solid map-get($var, systemBorderLight);
            color: map-get($var, systemTextSecondary);
        }

        >.MuiGrid-item,
        >.MuiGrid-item>span {
            display: flex;
            align-items: center;
            gap: 6px;
            @include text-truncate();

            >span {
                @include text-truncate();
                max-width: 160px;
            }

            >svg {
                height: 20px !important;
                width: 20px !important;
                flex-shrink: 0;
            }
        }
    }
}

/* password wrapper
--------------------------------------------------------------------------------------------------------*/
.password-wrapper {
    position: relative;

    .password-strength {
        position: absolute;
        left: 16px;
        top: calc(100% + 2px);
        display: flex;
        align-items: center;

        .help-btn {
            background: none;
            border: 0;
            padding: 4px;
            margin: -4px -4px -4px 0;
            color: currentColor;

            svg {
                display: block;
                height: 16px !important;
                width: 16px !important;
            }
        }

        &.very-weak {
            @include theme using ($var) {
                color: map-get($var, dangerError);
            }
        }

        &.weak {
            color: #FB8C00;
        }

        &.medium {
            @include theme using ($var) {
                color: map-get($var, warning);
            }
        }

        &.strong {
            @include theme using ($var) {
                color: map-get($var, primary600);
            }
        }

        &.very-strong {
            @include theme using ($var) {
                color: map-get($var, primary600);
            }
        }
    }
}

.password-strength-menu {
    &.MuiPopover-paper {
        width: 100%;
        max-width: 250px;
        padding: 16px;
    }

    .strength-list {
        >li {
            display: flex;
            align-items: flex-start;
            @include body-medium();

            &:not(:last-child) {
                margin-bottom: 12px;
            }

            svg {
                flex-shrink: 0;
                margin-right: 8px;
                opacity: 0.24;
            }

            >span {
                display: block;
                padding: 2px 0;
            }

            &.active {
                svg {
                    opacity: 1;

                    @include theme using ($var) {
                        color: map-get($var, primary600);
                    }
                }
            }
        }
    }
}

/* quoted item list
--------------------------------------------------------------------------------------------------------*/
.quoted-item-list {
    margin-bottom: 16px;

    @include theme using ($var) {
        border-top: 1px solid map-get($var, systemBorderLight);
    }

    >li {
        padding: 12px 0 6px;

        @include theme using ($var) {
            border-bottom: 1px solid map-get($var, systemBorderLight);
        }

        .fixed-width {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 160px;
            max-width: 160px;
        }

        .fill-width,
        .control-hint {
            flex-grow: 1;
        }

        .serial {
            flex-grow: 0;
            flex-shrink: 0;
            margin-bottom: 24px;
            border-radius: 50px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            @include body-large-semibold();
            height: 40px;
            width: 40px;
            flex-basis: 40px;
            max-width: 40px;

            @include theme using ($var) {
                background: map-get($var, secondaryBackground);
                color: map-get($var, systemTextSecondary);
            }
        }
    }
}

/* react draft js wysiwyg editor
--------------------------------------------------------------------------------------------------------*/
.rdw-editor-wrapper {
    margin-bottom: 24px;

    .rdw-editor-toolbar {
        padding: 8px 8px 2px;
        border-radius: 8px 8px 0 0;
        margin: 0;

        @include theme using ($var) {
            border: 1px solid map-get($var, systemBorder);
        }

        & * {
            box-sizing: content-box !important;
        }
    }

    .rdw-editor-main {
        border-top: 0;
        border-radius: 0 0 8px 8px;
        padding: 12px;
        min-height: 180px;

        @include theme using ($var) {
            border: 1px solid map-get($var, systemBorder);
        }

        .public-DraftStyleDefault-block {
            margin: 8px 0;
        }
    }
}

/* Chart
--------------------------------------------------------------------------------------------------------*/
.chart-legend {
    display: flex;
    align-items: center;
    @include body-medium();
    gap: 12px;

    li {
        display: flex;
        align-items: center;
        gap: 6px;

        &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 4px;
            background: currentColor;
        }

        span {
            @include theme using ($var) {
                color: map-get($var, systemTextPrimary);
            }
        }
    }
}

.semicircle-chart-wrapper {
    height: 152px;
    width: 258px;
    margin: 0 auto;
    padding: 14px 0 0;
}

.dashed-bar-chart {
    display: flex;
    align-items: flex-end;
    gap: 12px;

    >li {
        border-radius: 8px;
        flex-grow: 1;
        flex-shrink: 1;
        flex-wrap: 100%;
        border: 2px solid;
        padding: 4px;
        height: 50px;
        position: relative;
        transition: all 0.3s ease;

        .tooltip-object {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            z-index: 1;
        }

        .dash-wrapper {
            position: relative;
            height: 100%;
            border-radius: 2px;
            overflow: hidden;

            >svg {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

/* Overflow Wrapper
--------------------------------------------------------------------------------------------------------*/
.overflow-wrapper {
    position: relative;

    .content {
        overflow: hidden;
    }

    .overflow-action {
        position: absolute;
        left: 50%;
        bottom: 16px;
        transform: translateX(-50%);
        z-index: 2;
    }

    &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 120px;
        background: linear-gradient(to bottom, rgba(16, 16, 16, 0) 0%, rgba(16, 16, 16, 1) 100%);
        content: '';
    }

    &.hidden {
        .content {
            max-height: none !important;
            overflow: visible;
        }

        .overflow-action,
        &:after {
            display: none;
        }
    }
}

/* Achivements
--------------------------------------------------------------------------------------------------------*/
.achievements {
    text-align: center;

    .icon-wrapper {
        height: 44px;
        width: 44px;
        display: flex;
        margin: 0 auto 12px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid;
        position: relative;

        .progress {
            position: absolute;
            top: -6px;
            left: -6px;
            height: 54px;
            width: 54px;
        }

        @include theme using ($var) {
            border-color: map-get($var, systemBorder);
        }
    }
}

/* Sweet Alert
--------------------------------------------------------------------------------------------------------*/
body div:where(.swal2-container) {
    z-index: 1999;
}

body div:where(.swal2-container) div:where(.swal2-popup) {
    color: inherit;
    padding: 0;
    width: auto;
    background: none;
}

body div:where(.swal2-container) .swal2-html-container {
    padding: 0;
    overflow: visible;
}

body div:where(.swal2-container).swal2-backdrop-show,
body div:where(.swal2-container).swal2-noanimation {
    @include theme using ($var) {
        background-color: map-get($var, dialogBackdropColor);
    }
}

body .swal2-show {
    display: block !important;
}

.swal-confirm-btn {
    width: 100%;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    border: 0;
    margin: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: inherit;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    padding: 6px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-transform: none;
    border-radius: 8px;
    height: 44px;
    position: relative;
    padding-left: 24px;
    padding-right: 24px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    @include theme using ($var) {
        color: map-get($var, btnPrimaryTextColor);
        background: map-get($var, btnPrimaryBackground);
        box-shadow: map-get($var, shadow2) !important;
    }

    &:hover {
        @include theme using ($var) {
            background: map-get($var, btnHoverPrimaryBackground);
        }
    }
}

.swal-cancel-btn {
    width: 100%;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    border: 0;
    margin: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: inherit;
    padding: 6px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-transform: none;
    border-radius: 8px;
    min-width: auto;
    height: 44px;
    position: relative;
    padding-left: 24px;
    padding-right: 24px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

    @include theme using ($var) {
        color: map-get($var, btnSecondaryTextColor);
        background: map-get($var, btnSecondaryBackgroundColor);
    }

    &:hover {
        @include theme using ($var) {
            background: map-get($var, btnHoverSecondaryBackgroundColor);
        }
    }
}

.swal-confirm-btn,
.swal-cancel-btn {
    &:before {
        content: "";
        position: absolute;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        display: block;
        border-radius: 13px;
        -webkit-transform: scale(1.02);
        -moz-transform: scale(1.02);
        -ms-transform: scale(1.02);
        transform: scale(1.02);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        opacity: 0;
        visibility: hidden;

        @include theme using ($var) {
            border: 2px solid map-get($var, btnFocusRing);
        }
    }

    &:focus:before {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        visibility: visible;
    }
}

.delete-dialog-swal {
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: calc(100% - 64px);
    max-width: 768px;
    border-radius: 16px;
    padding: 24px;
    color: inherit;
    width: 400px;
    text-align: center;

    @include theme using ($var) {
        background: map-get($var, dialogBackgroundColor);
        box-shadow: map-get($var, shadow5);
    }

    .icon-wrapper {
        padding: 16px;
        margin: 8px auto 24px;
        border-radius: 50px;

        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
            background: map-get($var, secondaryBackground);
        }

        svg {
            display: block;
        }
    }

    .title {
        padding-bottom: 8px;
        color: inherit;
        @include headline-medium-bold();
    }

    .message {
        padding-bottom: 32px;
        max-width: 310px;
        margin: 0 auto;
        @include body-large-medium();

        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }
    }

    .btn-wrapper {
        display: flex;
        gap: 16px;
    }
}

/* Error page
--------------------------------------------------------------------------------------------------------*/
.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 40px;
    min-height: calc(100vh - 172px);

    >img {
        max-height: 128px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        max-width: 640px;

        p {
            @include theme using ($var) {
                color: map-get($var, systemTextSecondary);
            }
        }
    }
}

/* user role
--------------------------------------------------------------------------------------------------------*/
.user-role-block {
    margin-bottom: 32px;

    hr {
        margin-top: 8px;
        margin-bottom: 2px;
    }

    .MuiFormControlLabel-root {
        margin-bottom: -6px;
    }
}

/* welcome
--------------------------------------------------------------------------------------------------------*/
.welcome {
    padding: 48px 0;
    min-height: 100vh;
    background: center no-repeat;
    background-size: cover;
    position: relative;

    @include theme using ($var) {
        background-image: url(map-get($var, colorBackground));
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        z-index: 0;
        backdrop-filter: blur(240px);

        @include theme using ($var) {
            background-color: map-get($var, colorBackgroundOverlay);
        }
    }

    .primary-container {
        position: relative;
        z-index: 1;
    }

    .logo {
        margin: 0 auto 48px;
        display: block;
        max-height: 40px;
    }

    .features {
        padding: 32px;
        border-radius: 16px;
        margin-bottom: 56px;

        @include theme using ($var) {
            background-color: map-get($var, cardBackground);
            border: 1px solid map-get($var, systemBorderLight);
            box-shadow: map-get($var, shadow1);
        }

        .title {
            text-align: center;
            padding-bottom: 32px;
        }
    }

    .in-progress-block {
        text-align: center;

        .progress {
            max-width: 360px;
            margin: 0 auto 32px;
        }
    }

    .action-block {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }


    .page-title {
        text-align: center;
        max-width: 560px;
        margin: 0 auto;
        padding-bottom: 48px;

        h1 {
            padding-bottom: 4px;
        }
    }
}

.feature-list {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &.g-16 {
        gap: 16px;
    }

    li {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .icon-wrapper {
        flex-shrink: 0;
        padding: 8px;
        border-radius: 8px;

        @include theme using ($var) {
            background-color: map-get($var, subtlePrimaryBackground);
            color: map-get($var, onSubtlePrimaryBackground);
        }

        .icon {
            display: block;
            height: 32px;
            width: 32px;
        }
    }

    &.stroked {
        .icon-wrapper {
            border: 1px solid;

            @include theme using ($var) {
                border-color: map-get($var, subtlePrimaryStroke);
            }
        }
    }

    &.accent1 .icon-wrapper {
        @include theme using ($var) {
            background-color: map-get($var, accent1);
            color: map-get($var, onAccent1);
        }
    }

    &.accent2 .icon-wrapper {
        @include theme using ($var) {
            background-color: map-get($var, accent2);
            color: map-get($var, onAccent2);
        }
    }

    &.accent3 .icon-wrapper {
        @include theme using ($var) {
            background-color: map-get($var, accent3);
            color: map-get($var, onAccent3);
        }
    }
}

/* checkout header
--------------------------------------------------------------------------------------------------------*/
.checkout-header {
    padding: 20px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    @include theme using ($var) {
        background-color: map-get($var, cardBackground);
    }

    .primary-container {
        display: flex;
        align-items: center;
    }

    .logo {
        max-height: 40px;
        display: block;
    }

    .user-menu-trigger {
        margin-left: auto;
    }
}

.checkout-layout {
    display: flex;
    gap: 64px;

    .form-block {
        padding-top: 48px;
    }

    .form-block,
    .image-block {
        flex-grow: 0;
        flex-shrink: 0;
        max-width: 560px;
        flex-basis: 560px;

        img {
            display: block;
            max-width: 100%;
        }
    }

    .block-title {
        padding-bottom: 32px;
        max-width: 480px;

        h1 {
            padding-bottom: 8px;
        }

        img {
            display: block;
            max-height: 80px;
            margin-bottom: 32px;
        }
    }

    .action-block {
        padding-top: 32px;
        margin-top: 32px;
        border-top: 1px solid;
        display: flex;
        gap: 8px;

        .MuiButton-text:first-child {
            margin-left: -16px;
        }

        @include theme using ($var) {
            border-color: map-get($var, systemBorderLight);
        }
    }

    .image-block {
        margin-left: auto;

        img {
            display: block;
            border-radius: 16px;
            max-width: 100%;

            @include theme using ($var) {
                box-shadow: map-get($var, shadow4);
            }
        }
    }

    &.inner-page {
        padding: 24px 24px 40px 40px;

        .image-block {
            max-width: 520px;
            flex-basis: 520px;

            img {
                width: 100%;
                height: 640px;
                object-fit: cover;
            }
        }

        .form-block {
            padding-top: 16px;
        }
    }

}

/* Big Radio
--------------------------------------------------------------------------------------------------------*/
.big-radio {
    position: relative;
    cursor: pointer;
    display: block;

    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .content {
        padding: 16px;
        border-radius: 8px;
        border: 1px solid;

        @include theme using ($var) {
            border-color: map-get($var, systemBorder);
        }

        .description {
            @include theme using ($var) {
                color: map-get($var, systemTextSecondary);
            }
        }

        hr {
            margin: 12px 0;
        }

        .icon-wrapper {
            margin-top: -2px;
            margin-left: -2px;
            margin-bottom: 12px;

            @include theme using ($var) {
                color: map-get($var, checkboxRadioTextColor);
            }

            .default-icon {
                display: block;
            }

            .checked-icon {
                display: none;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: -6px;
            right: -6px;
            bottom: -6px;
            left: -6px;
            display: block;
            border: 4px solid;
            border-radius: 13px;
            transform: scale(1.02);
            transition: all 0.3s ease;
            opacity: 0;
            visibility: hidden;

            @include theme using ($var) {
                border-color: map-get($var, btnFocusRing);
            }
        }
    }

    input:checked~.content {
        border-width: 2px;
        padding: 15px;

        @include theme using ($var) {
            border-color: map-get($var, checkboxRadioCheckedColor);
        }

        .icon-wrapper {
            @include theme using ($var) {
                color: map-get($var, checkboxRadioCheckedColor);
            }

            .default-icon {
                display: none;
            }

            .checked-icon {
                display: block;
            }
        }
    }

    input:focus~.content {
        &:before {
            transform: scale(1);
            opacity: 0.32;
            visibility: visible;
        }
    }
}

.plan-list {
    @include body-small-medium();
    display: flex;
    flex-direction: column;
    gap: 6px;

    li {
        display: flex;
        gap: 6px;
        align-items: flex-start;

        .icon {
            flex-shrink: 0;

            @include theme using ($var) {
                color: map-get($var, checkboxRadioCheckedColor);
            }
        }
    }
}

.checkout-user-list {
    border-radius: 12px;
    border: 1px solid;
    overflow: hidden;
    position: relative;

    @include theme using ($var) {
        border-color: map-get($var, systemBorderLight);
    }

    >.inner {
        max-height: 368px;
        overflow: auto;
        padding: 8px 20px;

        li {
            padding: 12px 0;

            &:not(:last-child) {
                border-bottom: 1px solid;

                @include theme using ($var) {
                    border-color: map-get($var, systemBorderLight);
                }
            }
        }

        .action-wrapper {
            padding: 0;
            height: 60px;

            .MuiButton-root {
                position: absolute;
                bottom: 16px;
                left: 50%;
                transform: translateX(-50%);

                @include theme using ($var) {
                    box-shadow: map-get($var, shadow3);
                }
            }
        }
    }
}


.MuiGrid-item .big-radio {
    height: 100%;

    .content {
        height: 100%;
    }
}

/* Item Summary
--------------------------------------------------------------------------------------------------------*/
.item-summary {
    border: 1px solid;
    border-radius: 8px;
    margin-bottom: 32px;

    @include theme using ($var) {
        border-color: map-get($var, systemBorderLight);
    }

    ul {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        li {
            display: flex;

            p {
                @include theme using ($var) {
                    color: map-get($var, systemTextSecondary);
                }
            }

            span {
                text-align: right;
                margin-left: auto;
            }

            hr {
                flex-grow: 1;
            }
        }
    }

    .highlight {
        display: flex;
        border-radius: 0 0 7px 7px;
        padding: 20px;
        border-top: 1px solid;

        @include theme using ($var) {
            border-color: map-get($var, systemBorderLight);
            background-color: map-get($var, bgBrandWeak);
        }

        p {
            @include theme using ($var) {
                color: map-get($var, textBrandStrong);
            }
        }

        span {
            text-align: right;
            margin-left: auto;
        }
    }

    .MuiPopover-paper & {
        border: 0;
        margin: 0;
        border-radius: 0;
        width: 360px;

        ul {
            padding: 16px;
        }
    }
}

/* upgrade card
--------------------------------------------------------------------------------------------------------*/
.upgrade-card {
    padding: 32px;
    text-align: center;

    h6 {
        padding-bottom: 4px;
    }

    p {
        max-width: 340px;
        margin: 0 auto;
    }

    .action-btn {
        margin-top: 20px;
    }

    .icon-wrapper {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 72px;
        width: 72px;
        border: 1px solid;
        margin: 0 auto 16px;

        @include theme using ($var) {
            color: map-get($var, textBrandColor);
            border-color: map-get($var, systemBorder);
        }
    }
}

/* alert
--------------------------------------------------------------------------------------------------------*/
.alert {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    padding: 12px;
    border: 1px solid;

    @include theme using ($var) {
        color: map-get($var, systemTextSecondary);
        background: map-get($var, wrapperBackground);
        border-color: map-get($var, systemBorderLight);
    }

    .title {
        display: flex;
        align-items: center;
        gap: 6px;


        svg {
            display: block;
            flex-shrink: 0;
        }
    }
}

/* Saved Card
--------------------------------------------------------------------------------------------------------*/
.saved-card {
    padding: 16px 16px 12px;
    border-radius: 8px;
    border: 1px solid;
    width: 160px;
    background: none;
    transition: all 0.3s ease;
    text-align: left;
    font-weight: inherit;
    position: relative;
    height: 100%;
    cursor: pointer;

    @include theme using ($var) {
        border-color: map-get($var, systemBorder);
    }

    .logo {
        display: block;
        height: 24px;
        margin-bottom: 20px;
    }

    &:before {
        content: '';
        position: absolute;
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
        display: block;
        border: 4px solid;
        border-radius: 13px;
        transform: scale(1.02);
        transition: all 0.3s ease;
        opacity: 0;
        visibility: hidden;

        @include theme using ($var) {
            border-color: map-get($var, btnFocusRing);
        }
    }

    &.selected {
        border-width: 2px;
        padding: 15px 15px 11px;

        @include theme using ($var) {
            border-color: map-get($var, checkboxRadioCheckedColor);
        }
    }

    &:focus {
        &:before {
            transform: scale(1);
            opacity: 0.32;
            visibility: visible;
        }
    }

    &.add-card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;

        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }
    }
}

/* message preview
--------------------------------------------------------------------------------------------------------*/
.message-preview {
    background: url('../images/message-bg.jpg') center no-repeat;
    background-size: cover;
    border-radius: 8px;
    padding: 28px 16px;
}

.message {
    font-family: system-ui,
        -apple-system,
        "Segoe UI",
        Roboto,
        "Helvetica Neue",
        "Noto Sans",
        "Liberation Sans",
        Arial,
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
        "Noto Color Emoji";
    padding-left: 8px;
    max-width: fit-content;
    width: 248px;
    display: inline-block;
    vertical-align: top;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.16));
    position: relative;

    &:before {
        border-style: solid;
        border-width: 0 8px 11px 0;
        border-color: transparent #fff transparent transparent;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        border-top-left-radius: 1px;
    }

    .block {
        border-radius: 0 6px 6px 6px;
        background: #fff;
        color: #282828;
        font-size: 13px;
        line-height: 18px;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 12px;


        .head {
            font-size: 14px;
            color: #3D3D3D;
            font-weight: 700;
            padding: 0 8px;

            a {
                display: block;
                position: relative;
            }

            img,
            video {
                border-radius: 4px;
                width: 100%;
                min-width: 180px;
                object-fit: cover;
                height: 144px;
            }

            .video-button {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                border-radius: 50px;
                background: rgba(0, 0, 0, 0.64);
                backdrop-filter: blur(4px);
                color: #fff;
            }

            .document {
                display: flex;
                align-items: center;
                padding: 6px;
                gap: 4px;
                font-size: 13px;
                border-radius: 4px;
                background: rgba(0, 0, 0, 0.08);

                svg {
                    color: #F54646;
                    flex-shrink: 0;
                }

                span {
                    display: block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 100%;
                    min-width: 0;
                }
            }
        }

        .body {
            padding: 0 8px;

            strong {
                font-weight: 700;
            }

            em {
                font-style: italic;
            }

            del {
                text-decoration: line-through;
            }
        }

        .footer {
            color: #8c8c8c;
            padding: 0 8px;
        }

        .time {
            display: flex;
            align-items: center;
            gap: 4px;
            justify-content: flex-end;
            color: #8C8C8C;
            font-size: 11px;
            line-height: 14px;
            margin-top: -9px;
            padding: 0 8px 5px;
        }

        .button-wrapper {
            margin-top: -12px;
            border-top: 1px solid #DADDE1;
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 45px;
            gap: 6px;
            border-bottom: 1px solid #DADDE1;
            font-size: 14px;
            color: #00A5F4;

            &:last-child {
                border-bottom: 0;
            }

            svg {
                color: #349AC8;
            }
        }
    }

    &.sent {
        padding-left: 0;
        padding-right: 8px;

        .block {
            background: #D8FDD2;
            border-radius: 6px 0 6px 6px;
        }

        &:before {
            left: auto;
            right: 0;
            border-width: 0 0 11px 8px;
            border-color: transparent transparent transparent #D8FDD2;
        }
    }
}

/* add template block
--------------------------------------------------------------------------------------------------------*/
.add-template-block {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    .preview-block {
        position: sticky;
        top: 0;
        flex-shrink: 0;
        max-width: 368px;
        flex-basis: 368px;
        flex-grow: 0;
    }

    .form-block {
        flex-shrink: 0;
        max-width: calc(100% - 384px);
        flex-basis: calc(100% - 384px);
        flex-grow: 0;
    }
}

/* Inbox
--------------------------------------------------------------------------------------------------------*/
.inbox-layout {
    display: flex;
    height: calc(100vh - 172px);
    min-height: 400px;

    .contacts {
        flex-grow: 0;
        flex-shrink: 0;
        max-width: 400px;
        flex-basis: 400px;
        display: flex;
        flex-direction: column;
    }

    .message-panel {
        flex-grow: 0;
        flex-shrink: 0;
        max-width: calc(100% - 400px);
        flex-basis: calc(100% - 400px);
        padding-left: 16px;

        .inner {
            border-radius: 12px;
            background: url('../images/message-bg.jpg') center no-repeat;
            background-size: cover;
            position: relative;
            overflow: hidden;
            height: 100%;

            &:after,
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 76px;
                display: block;
                z-index: 1;
                opacity: 0.72;
                background: linear-gradient(to top, rgba(237, 226, 218, 0) 0%, rgba(237, 226, 218, 1) 100%);
            }

            &:after {
                top: auto;
                bottom: 0;
                background: linear-gradient(to bottom, rgba(237, 226, 218, 0) 0%, rgba(237, 226, 218, 1) 100%);
            }

            .message-info {
                position: absolute;
                z-index: 1;
                left: 12px;
                top: 12px;
                right: 12px;
                padding: 10px 12px;
                border-radius: 8px;
                backdrop-filter: blur(24px);
                font-family: inherit;

                @include theme using ($var) {
                    background: rgba(255, 255, 255, 0.8);
                    box-shadow: map-get($var, shadow1);
                }
            }

            @include theme using ($var) {
                box-shadow: map-get($var, shadow1);
            }
        }
    }

    .chat-box {
        position: absolute;
        bottom: 12px;
        left: 12px;
        right: 12px;
        z-index: 2;

        .message-input {
            background: #fff;
            border-radius: 100px;
            border: 0;
            height: 48px;
            width: 100%;
            border: 2px solid transparent;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
            transition: all 0.3s ease;
            padding: 4px 20px;
            color: inherit;
            @include body-large-medium();

            &.error {
                @include theme using ($var) {
                    border-color: map-get($var, textDangerColor);
                }
            }

            &:focus {
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16), 0 0 0 4px rgba(41, 163, 133, 0.4);
            }

            &::-webkit-input-placeholder {
                @include theme using ($var) {
                    color: map-get($var, systemTextTertiary);
                }
            }

            &::-moz-placeholder {
                @include theme using ($var) {
                    color: map-get($var, systemTextTertiary);
                }
            }

            &:-ms-input-placeholder {
                @include theme using ($var) {
                    color: map-get($var, systemTextTertiary);
                }
            }

            &:-moz-placeholder {
                @include theme using ($var) {
                    color: map-get($var, systemTextTertiary);
                }
            }
        }

        .send-btn {
            border-radius: 100px;
            background: #1DAA61;
            height: 48px;
            width: 48px;
            display: inline-flex;
            vertical-align: top;
            align-items: center;
            justify-content: center;
            color: #fff;
            border: 0;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.32);
            transition: all 0.3s ease;

            &:focus {
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.32), 0 0 0 4px rgba(41, 163, 133, 0.4);
            }

            &:hover,
            &:active,
            &:focus {
                background: darken(#1DAA61, 8%);
            }
        }
    }

    .messages {
        padding: 88px 12px 76px;
        height: 100%;
        scrollbar-width: thin;
        transition: all 0.3s ease;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: relative;
        z-index: 0;

        .message:first-child {
            margin-top: auto;
        }

        .message.sent {
            margin-left: auto;
        }

        @include theme using ($var) {
            scrollbar-color: transparentize(map-get($var, statusBadgeDarkBackgroundColor), 0.64) transparent;
        }

        &:hover {
            @include theme using ($var) {
                scrollbar-color: map-get($var, statusBadgeDarkBackgroundColor) transparent;
            }
        }

        &::-webkit-scrollbar {
            width: 16px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            border: 3px solid transparent;

            @include theme using ($var) {
                background-color: map-get($var, statusBadgeDarkBackgroundColor);
            }
        }

        .message {
            width: 320px;
        }
    }

    .contact-list {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
        scrollbar-width: thin;
        transition: all 0.3s ease;

        @include theme using ($var) {
            scrollbar-color: transparentize(map-get($var, statusBadgeDarkBackgroundColor), 0.64) transparent;
        }

        &:hover {
            @include theme using ($var) {
                scrollbar-color: map-get($var, statusBadgeDarkBackgroundColor) transparent;
            }
        }

        &::-webkit-scrollbar {
            width: 16px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            border: 3px solid transparent;

            @include theme using ($var) {
                background-color: map-get($var, statusBadgeDarkBackgroundColor);
            }
        }

        .contact-btn {
            background: none;
            border: 0;
            display: flex;
            align-items: center;
            padding: 12px 16px;
            gap: 12px;
            text-align: left;
            position: relative;
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
                background: rgba(0, 0, 0, 0.04);
            }

            &.active:after {
                right: 0;
                top: 0;
                bottom: 0;
                width: 4px;
                content: '';
                position: absolute;
                display: block;

                @include theme using ($var) {
                    background: map-get($var, textBrandColor);
                }
            }

            &:not(:last-child) {
                @include theme using ($var) {
                    border-bottom: 1px solid map-get($var, systemBorderLight);
                }
            }

            .avatar-initial {
                flex-shrink: 0;
            }

            .contact {
                min-width: 0;

                .name {
                    text-overflow: ellipsis;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .phone {
                    text-overflow: ellipsis;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;

                    @include theme using ($var) {
                        color: map-get($var, systemTextSecondary);
                    }
                }
            }

            .count {
                flex-shrink: 0;
                margin-left: auto;
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 18px;
                padding: 0 4px;
                height: 18px;
                border-radius: 50px;
                text-align: center;
                @include label-small-bold();

                line-height: 18px;


                @include theme using ($var) {
                    background: map-get($var, statusBadgePrimaryBackgroundColor);
                    color: map-get($var, statusBadgePrimaryTextColor);
                }
            }
        }
    }
}

/* stripe textbox
--------------------------------------------------------------------------------------------------------*/
.stripe-group {
    margin-bottom: 24px;
    position: relative;

    .control-label {
        position: absolute;
        top: 5px;
        left: 16px;
        @include label-medium();

        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }
    }

    .form-control {
        height: 48px;
        border-radius: 8px;
        @include body-large();
        color: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 14px;
        transition: border-color 0.3s ease;

        @include theme using ($var) {
            border: 1px solid map-get($var, systemBorder);
        }

        &.StripeElement--focus {
            padding-left: 15px;
            padding-right: 15px;

            @include theme using ($var) {
                border: 2px solid map-get($var, textBrandColor);
            }
        }

        &.StripeElement--invalid {
            padding-left: 15px;
            padding-right: 15px;

            @include theme using ($var) {
                border: 2px solid map-get($var, textDangerColor);
            }
        }
    }

    #cc-number .__PrivateStripeElement div:nth-child(3):last-child {
        transform: scale(0);
        visibility: hidden;
    }
}

/* public profile
--------------------------------------------------------------------------------------------------------*/
.public-profile {
    padding: 32px 12px 40px;
    min-height: 100vh;

    @include theme using ($var) {
        background: map-get($var, wrapperBackground);
    }

    .profile-card {
        position: relative;
        padding: 32px 24px 24px;
    }

    .inner {
        max-width: 624px;
        margin: 0 auto;
    }

    .logo {
        display: block;
        max-height: 40px;
        margin: 0 auto 32px;
    }

    .abstract-art {
        display: block;
        border-radius: 12px 12px 0 0;
        margin: 0 auto;
        width: 100%;
        object-fit: cover;
        height: 120px;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
    }

    .profile-pic {
        height: 128px;
        width: 128px;
        position: relative;
        z-index: 1;
        border-radius: 500px;
        margin-bottom: 16px;

        @include theme using ($var) {
            border: 6px solid map-get($var, bodyBackground);
            box-shadow: map-get($var, shadow2);
        }

        img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 500px;
        }

        .avatar-initial {
            height: 100%;
            width: 100%;
            font-size: 48px;
            line-height: 100%;
        }
    }

    .profile-info {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        li {
            display: flex;
            align-items: center;
            gap: 8px;

            &:not(:last-child):after {
                content: '';
                height: 4px;
                width: 4px;
                display: block;

                @include theme using ($var) {
                    background: map-get($var, btnHoverSecondaryBackgroundColor);
                }
            }

            a {
                display: flex;
                align-items: center;
                gap: 4px;
                border-radius: 50px;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }
    }

    .separator {
        margin: 16px 0;
    }

    .share-btn {
        position: absolute;
        top: 132px;
        right: 16px;
    }

    .social-card {
        padding: 16px 25px;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            left: 12px;
            right: 12px;
            display: block;
            top: -1px;

            @include theme using ($var) {
                border-top: 2px dashed map-get($var, systemBorderLight);
            }
        }
    }

    .social-btns {
        margin-right: -8px;
        margin-left: auto;
    }
}

.user-public-profile-info {
    .user {
        display: block;
        height: 80px;
        width: 80px;
        border-radius: 50px;
        object-fit: cover;
    }

    .social-btns {
        margin-left: -8px;
    }

    .user-details {
        display: flex;
        flex-direction: column;
        gap: 4px;

        @include theme using ($var) {
            color: map-get($var, systemTextSecondary);
        }

        li {
            a {
                display: flex;
                align-items: center;
                gap: 8px;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }
    }
}

.user-qr {
    max-width: 215px;
    margin: 0 auto 24px;
    text-align: center;

    @include theme using ($var) {
        color: map-get($var, systemTextSecondary);
    }

    img {
        display: block;
        height: 120px;
        width: 120px;
    }
}

/* Media Queries
--------------------------------------------------------------------------------------------------------*/
@media(max-width:1599px) {
    .checkout-layout {
        &.inner-page {
            gap: 24px;

            .image-block {
                max-width: 480px;
                flex-basis: 480px;

                img {
                    height: 560px;
                }
            }
        }
    }
}

@media(max-width:1499px) {
    .page-search {
        width: 200px;
    }

    .checkout-layout {
        &.inner-page {
            .image-block {
                max-width: 400px;
                flex-basis: 400px;

                img {
                    height: 480px;
                }
            }
        }
    }
}

@media(max-width: 1399px) {
    .quoted-item-list {
        >li {
            padding-bottom: 0;

            .fixed-width {
                flex-basis: 100%;
                max-width: none;
            }

            .bottom-row {
                margin-bottom: -8px;
            }
        }
    }

    .overflow-wrapper.xxl {

        .overflow-action,
        &:after {
            display: none;
        }
    }

    .checkout-layout {
        gap: 24px;

        &.inner-page {
            padding: 40px;

            .form-block {
                padding-top: 0;
            }

            .image-block {
                display: none;
            }
        }
    }

    .add-template-block {
        .preview-block {
            max-width: 320px;
            flex-basis: 320px;
        }

        .form-block {
            max-width: calc(100% - 336px);
            flex-basis: calc(100% - 336px);
        }
    }

    .inbox-layout {
        .contacts {
            max-width: 33.3333%;
            flex-basis: 33.3333%;
        }

        .message-panel {
            max-width: 66.6667%;
            flex-basis: 66.6667%;
        }
    }
}

@media(max-width:1199px) {
    .main-layout {
        .main-content {
            padding: 24px 24px 76px 24px;
        }
    }

    .kanban-wrapper {
        margin: 0 -24px -76px;
        padding: 0 24px;

        .board-wrapper {
            &:after {
                flex-basis: 8px;
                max-width: 8px;
            }
        }
    }

    .inline-details {

        >li,
        >li>span {
            &.before-checkbox:after {
                display: block;
            }

            &.checkbox-wrapper {
                margin-left: 0;
            }
        }
    }

    .track-users {
        height: auto;
        min-height: auto;
    }

    .track-user-map {
        height: 480px;
        min-height: auto;
    }

    .overflow-wrapper.xl {

        .overflow-action,
        &:after {
            display: none;
        }
    }

    .checkout-layout {
        .form-block {
            padding-top: 0;
            max-width: 640px;
            flex-basis: 640px;
        }

        .image-block {
            display: none;
        }

        &.inner-page {
            padding: 24px;

            .form-block {
                max-width: 100%;
                flex-basis: 100%;
            }
        }
    }

    .primary-container {
        &.checkout-layout {
            padding-bottom: 40px;
        }
    }

    .add-template-block {
        .preview-block {
            display: none;
        }

        .form-block {
            max-width: 100%;
            flex-basis: 100%;
        }
    }

    .inbox-layout {
        .contacts {
            max-width: 40%;
            flex-basis: 40%;
        }

        .message-panel {
            max-width: 60%;
            flex-basis: 60%;
        }

        .messages {
            .message {
                width: 260px;
            }
        }
    }
}

@media (max-width:991px) {

    body,
    body.sidebar-collapsed {
        .sidebar {
            .logo-wrapper {
                padding: 24px 16px 48px 16px;
                flex-direction: column-reverse;

                .logo {
                    display: none;
                }

                .logo-collapsed {
                    display: block;
                    margin-top: 0;
                }

                .icon-button {
                    display: none;
                }
            }

            .main-nav {
                >li {
                    >a {
                        padding: 20px 28px;

                        svg {
                            margin: 0;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }
        }

        .main-layout {
            .sidebar {
                flex-basis: 80px;
                max-width: 80px;
            }

            .main-content {
                flex-basis: calc(100% - 80px);
                max-width: calc(100% - 80px);
            }
        }
    }

    .primary-list {
        >li {
            .actions {
                top: 4px;
                right: 4px;
            }
        }
    }

    .overflow-wrapper.lg {

        .overflow-action,
        &:after {
            display: none;
        }
    }

    .feature-list:not(.no-wrap) {
        flex-direction: row;
        gap: 0;
        text-align: center;
        margin-left: -16px;
        margin-right: -16px;
        flex-wrap: wrap;

        li {
            flex-direction: column;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 25%;
            max-width: 25%;
            padding-left: 16px;
            padding-right: 16px;
            gap: 12px;

            p {
                @include body-medium();
            }
        }
    }

    .checkout-layout {
        .form-block {
            max-width: 100%;
            flex-basis: 100%;
        }

        .block-title {
            max-width: 560px;
        }
    }
}

@media(max-width:767px) {
    body:not(.public-profile-page) {
        .main-wrapper {
            display: none;
        }

        .mobile-not-supported {
            display: flex;
            flex-direction: column;
        }
    }

    .public-profile {
        padding-top: 16px;
        padding-bottom: 24px;

        .logo {
            margin-bottom: 24px;
        }

        .profile-card {
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 16px;
        }

        .social-card {
            padding: 16px;

            .social-btns {
                margin: -2px auto -6px;
            }
        }

        .share-btn {
            right: 8px;
        }
    }
}